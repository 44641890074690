import { Suspense, lazy } from 'react';

import { PageLoader } from 'shared/ui/PageLoader';

const TrainingLazy = lazy(() => import('./Training').then((module) => ({ default: module.Training })));
const TrainingNoCodeLazy = lazy(() =>
  import('./TrainingNoCode').then((module) => ({ default: module.TrainingNoCode })),
);
const TrainingCodeLazy = lazy(() =>
  import('./TrainingCode').then((module) => ({ default: module.TrainingCode })),
);

export const Training = () => (
  <Suspense fallback={<PageLoader />}>
    <TrainingLazy />
  </Suspense>
);

export const TrainingNoCode = () => (
  <Suspense fallback={<PageLoader />}>
    <TrainingNoCodeLazy />
  </Suspense>
);

export const TrainingCode = () => (
  <Suspense fallback={<PageLoader />}>
    <TrainingCodeLazy />
  </Suspense>
);
