import { useForm } from 'react-hook-form';

import isEmail from 'validator/lib/isEmail';

import { useJoinTrainWaitlistMutation } from 'shared/api/user/useJoinTrainWaitlistMutation';
import { catchError } from 'shared/helpers/parseAxiosError';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Modal } from 'shared/ui/Modal';
import { toaster } from 'shared/ui/Toast';

import wrenchSrc from '../assets/wrench.svg';

type Props = {
  isOpen: boolean;
  modelId: string;
  onOpenChange: (isOpen: boolean) => void;
};

type FormValues = {
  email: string;
};

export const TrainSoonModal = ({ isOpen, modelId, onOpenChange }: Props) => {
  const { mutateAsync: joinWaitlist } = useJoinTrainWaitlistMutation();

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    defaultValues: { email: '' },
    mode: 'onChange',
  });

  const handleJoinWhitelist = async (values: FormValues) => {
    if (!values.email) {
      toaster.error('No email is defined');
      return;
    }

    try {
      await joinWaitlist({ email: values.email, model_id: modelId });

      toaster.info(`You've been added to the list`);
      onOpenChange(false);
    } catch (e) {
      catchError(e);
    }
  };

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="w-full !max-w-md">
        <Modal.CloseButton />

        <img className="mx-auto mt-2 w-20" src={wrenchSrc} />

        <h1 className="mx-auto mb-3 mt-4 max-w-[70%] text-center text-xl/6 font-semibold md:text-2xl/7">
          Nesa AutoTrain is coming soon
        </h1>
        <div className="mx-auto mb-4 flex max-w-full justify-center text-center text-sm font-light text-clay-500 md:text-base">
          Enter your email address to whitelist for it <br /> when it gets released to be the first to use it.
        </div>

        <div className="flex flex-col gap-2">
          <Input
            errorSpacing
            {...register('email', { validate: (val) => (!isEmail(val) ? 'Invalid email' : true) })}
            error={errors.email?.message}
            label={
              <span>
                Email <span className="text-red-900">*</span>
              </span>
            }
            placeholder="Email Address"
          />

          <Button className="w-full" disabled={!isValid} onClick={handleSubmit(handleJoinWhitelist)}>
            Join Whitelist
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};
