import { useState } from 'react';
import { Link } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import type { ModelCollection } from 'shared/api/model-collections';

import logoLetterSrc from 'app/assets/images/logo-letter.svg';
import {
  AddModelsToCollections,
  CreateModelCollections,
  EditModelCollections,
} from 'features/ModelCollections';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Chip } from 'shared/ui/Chip';
import { Icon } from 'shared/ui/Icon';
import { Popover } from 'shared/ui/Popover';
import { Tabs } from 'shared/ui/Tabs';
import { toaster } from 'shared/ui/Toast';

import type { GetModelParamsCb } from './ui/SdkCodeModal';

import { DownloadModal } from './ui/DownloadModal';
import { MorePopover } from './ui/MorePopover';
import { ReportModal } from './ui/ReportModal';
import { TrainSoonModal } from './ui/TrainSoonModal';
import { UseModelPopover } from './ui/UseModelPopover';

export type Section = 'description' | 'playground' | 'reviews';

type Props = {
  downloads: number;
  getModelParams: GetModelParamsCb;
  modelId: string;
  onChange: (value: Section) => void;
  value: Section;
};

const getFormattedDownloads = (downloads: number) => {
  if (downloads < 1000) return downloads;

  if (downloads < 1000000) return `${(downloads / 1000).toFixed(1)}k`;

  return `${(downloads / 1000000).toFixed(2)}m`;
};

export const QueryMenu = ({ downloads, getModelParams, modelId, onChange, value }: Props) => {
  const lg = useMinWidthMediaQuery('lg');

  const [isTrainOpen, setIsTrainOpen] = useState(false);
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const [isUseModelOpen, setIsUseModelOpen] = useState(false);
  const [isTrainModalOpen, setIsTrainModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [isAddCollectionModalOpen, setIsAddCollectionModalOpen] = useState(false);
  const [isCreateCollectionModalOpen, setIsCreateCollectionModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [editModelCollection, setEditModelCollection] = useState<ModelCollection>();

  return (
    <div className="flex flex-col-reverse items-stretch gap-1.5 pr-px lg:flex-row lg:items-center lg:justify-between lg:overflow-x-auto">
      <Tabs.Root
        className="w-full"
        onValueChange={onChange}
        tabs={
          <Tabs.List className="h-fit flex-col md:flex-row">
            <Tabs.Trigger className="whitespace-nowrap" value="playground">
              <Icon className="size-3.5" name="box" />
              Model Playground
            </Tabs.Trigger>
            <Tabs.Trigger value="description">
              <Icon className="size-3.5" name="book" />
              Description
            </Tabs.Trigger>
            <Tabs.Trigger value="reviews">
              <Icon className="size-3.5" name="book" />
              Reviews
              <Chip className="flex items-center gap-1.5 truncate bg-white pr-1 text-clay-500 transition-colors">
                <Icon className="size-3 stroke-clay-500" name="penLine" />
                Write a Review{' '}
                <span className="rounded-full bg-clay-10 px-1 py-0 text-[9px] uppercase text-primary-800">
                  GET points
                </span>
              </Chip>
            </Tabs.Trigger>
          </Tabs.List>
        }
        value={value}
        variant="button"
      />

      <div className="ml-auto flex min-w-fit items-center gap-3">
        <Popover onOpenChange={setIsTrainOpen} open={isTrainOpen}>
          <Popover.Trigger asChild>
            <div
              className={twMerge(
                'group flex h-9 cursor-pointer items-center gap-0 rounded-lg bg-white pl-3 pr-2 text-sm text-clay-800',
                isTrainOpen && 'shadow-sm',
              )}
            >
              <Icon
                className="mr-2 size-3.5 text-clay-800 transition-colors group-hover:text-primary-800"
                name="wrench"
              />{' '}
              Train
              <Icon
                className={twMerge(
                  'ml-0.5 size-5 text-clay-800 transition-transform',
                  isTrainOpen && 'rotate-180',
                )}
                name="arrowDownSm"
              />
            </div>
          </Popover.Trigger>
          <Popover.Content
            align="end"
            className="rounded-lg !p-2"
            collisionPadding={4}
            side="bottom"
            sideOffset={4}
          >
            <Link
              className="flex cursor-pointer gap-3 rounded-lg border border-clay-20 px-4 py-3 transition-colors hover:border-clay-40"
              onClick={() => {
                setIsTrainModalOpen(true);
              }}
              to={`/training?modelId=${modelId}`}
            >
              <img className="size-4" src={logoLetterSrc} />
              <div className="flex flex-col gap-1.5">
                <div className="text-base/none font-semibold">Nesa Autotrain</div>
                <div className="text-sm/none font-light text-clay-500">Fine-tune this model without code</div>
              </div>
            </Link>
          </Popover.Content>
        </Popover>

        <UseModelPopover
          getModelParams={getModelParams}
          isOpen={isUseModelOpen}
          onOpenChange={setIsUseModelOpen}
        />

        {lg && (
          <button
            className="group flex h-9 items-center rounded-lg bg-white pl-3 pr-2.5 text-sm "
            onClick={() => setIsDownloadModalOpen(true)}
          >
            <Icon className="mr-2 size-3.5 transition-colors group-hover:text-primary-800" name="download" />
            Download
            <Chip className="ml-1.5 bg-clay-10 text-primary-800">{getFormattedDownloads(downloads)}</Chip>
          </button>
        )}

        <MorePopover
          isOpen={isMoreOpen}
          onActionClick={(action) => {
            if (action === 'report') {
              setIsReportModalOpen(true);
              return;
            }
            if (action === 'add-to-collection') {
              setIsAddCollectionModalOpen(true);
              return;
            }

            if (action === 'download') {
              setIsDownloadModalOpen(true);

              return;
            }

            toaster.info('Coming soon');
          }}
          onOpenChange={setIsMoreOpen}
        />
      </div>

      {isTrainModalOpen && (
        <TrainSoonModal isOpen={isTrainModalOpen} modelId={modelId} onOpenChange={setIsTrainModalOpen} />
      )}

      {isAddCollectionModalOpen && (
        <AddModelsToCollections
          modelId={modelId}
          onEditModelCollection={(modelCollection) => {
            setIsAddCollectionModalOpen(false);
            setEditModelCollection(modelCollection);
          }}
          onOpenChange={setIsAddCollectionModalOpen}
          onOpenCreateCollection={() => {
            setIsAddCollectionModalOpen(false);
            setIsCreateCollectionModalOpen(true);
          }}
          open={isAddCollectionModalOpen}
        />
      )}

      {isCreateCollectionModalOpen && (
        <CreateModelCollections
          onOpenChange={(open) => {
            setIsCreateCollectionModalOpen(open);
            if (!open) {
              setIsAddCollectionModalOpen(true);
            }
          }}
          open={isCreateCollectionModalOpen}
        />
      )}

      {editModelCollection && (
        <EditModelCollections
          modelCollection={editModelCollection}
          onOpenChange={() => {
            setEditModelCollection(undefined);
            setIsAddCollectionModalOpen(true);
          }}
          open={!!editModelCollection}
        />
      )}

      {isReportModalOpen && (
        <ReportModal isOpen={isReportModalOpen} modelId={modelId} onOpenChange={setIsReportModalOpen} />
      )}

      {isDownloadModalOpen && (
        <DownloadModal isOpen={isDownloadModalOpen} modelId={modelId} onOpenChange={setIsDownloadModalOpen} />
      )}
    </div>
  );
};
