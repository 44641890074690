import type { Model } from 'shared/api/models/types';

import { openAiPricesByName, openAiPricesByType } from 'shared/config/prices';
import { getPrice } from 'shared/helpers/getPrice';
import { Icon } from 'shared/ui/Icon';
import { Tooltip } from 'shared/ui/Tooltip';

type Props = {
  model: Model | undefined;
};

const getPriceComparison = (model: Model) => {
  const openAiPriceByType = model?.type && openAiPricesByType[model.type];
  const openAiPriceByName = model?.modelName && openAiPricesByName[model.modelName];

  let value: null | number = null;

  if (openAiPriceByType && openAiPriceByType.input && model.pricing?.input_price) {
    value = (openAiPriceByType.input - model.pricing.input_price) / openAiPriceByType.input;
  }

  if (
    typeof openAiPriceByName === 'object' &&
    typeof openAiPriceByName.output === 'number' &&
    model.pricing?.output_price
  ) {
    value = (openAiPriceByName.output - model.pricing.output_price) / openAiPriceByName.output;
  }

  if (typeof value !== 'number') return;

  if (value > 0.9) {
    return Math.ceil(Number(value.toFixed(3)) * 100);
  }

  return Number(value.toFixed(1)) * 100;
};

export const Pricing = ({ model }: Props) => {
  const openAiPriceDiff = model && getPriceComparison(model);

  return (
    <Tooltip
      className="px-3 py-2 text-xs"
      content={
        model?.pricing ? (
          <div className="flex flex-col gap-1 text-xs">
            <div className="flex items-center justify-between gap-3 font-light">
              <div className="text-clay-100">
                <b>${getPrice(model.pricing.input_price)}</b> / 1M tokens
              </div>
              <div className="text-[#61728E]">input</div>
            </div>
            <div className="flex items-center justify-between gap-3 font-light">
              <div className="text-clay-100">
                <b>${getPrice(model.pricing.output_price)}</b> / 1M tokens
              </div>
              <div className="text-[#61728E]">output</div>
            </div>

            {typeof openAiPriceDiff === 'number' && openAiPriceDiff > 0 && (
              <div className="flex">
                <span className="mr-1 text-tusk-200">{openAiPriceDiff}% less</span> vs. OpenAI
              </div>
            )}
          </div>
        ) : (
          'per token'
        )
      }
      side="bottom"
    >
      <span
        className="ml-auto mt-px inline-flex items-center gap-1 font-semibold text-clay-900"
        onClick={(e) => e.stopPropagation()}
      >
        <Icon className="size-[1.125rem]" name="dollar" safeArea="0" />
        <span>
          {(model?.pricing?.output_price && getPrice(model?.pricing?.output_price)) ||
            model?.price?.toFixed(3) ||
            0.001}
        </span>
      </span>
    </Tooltip>
  );
};
