import { type FormEvent, type InputHTMLAttributes, forwardRef } from 'react';
import { Link, useParams } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import type { QueryParams } from 'features/ModelQueryParams/types';
import type { Section } from 'features/QueryMenu/QueryMenu';
import type { Model } from 'shared/api/models/types';
import type { ClassName } from 'shared/types';

import { useUser } from 'app/stores/user';
import { UpgradeToProTriggerModal } from 'features/UpgradeToProModal';
import { getIsApiModel } from 'pages/QueryPage/helpers/getIsApiModel';
import { getIsZkAvailable } from 'pages/QueryPage/helpers/getIsZkAvailable';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Label } from 'shared/ui/Label';
import { Switch } from 'shared/ui/Switch';
import { Tooltip } from 'shared/ui/Tooltip';

type Params = {
  private?: boolean;
};

type Props = {
  innerClassName?: string;
  isError?: boolean;
  isLoading?: boolean;
  isSubmitDisabled?: boolean;
  model?: Model;
  onParamsChange: (params: Partial<QueryParams>) => void;
  onSubmit?: (e: FormEvent<HTMLButtonElement>) => void;
  params: Params;
  tooltipText?: string;
} & ClassName &
  Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'onSubmit'>;

export const ChatTextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      className,
      disabled,
      innerClassName,
      isError,
      isLoading,
      isSubmitDisabled,
      model,
      onParamsChange,
      onSubmit,
      params,
      tooltipText = '',
      ...props
    }: Props,
    ref,
  ) => {
    const { section } = useParams<{ id: string; section?: Section }>();

    const { proVersion } = useUser();

    const isZkAvailable = model && getIsZkAvailable(model);
    const isPrivateAvailable = model && !getIsApiModel(model);
    // const isPrivateAvailable = model
    //   ? model.type === 'text-generation' && model.org && !['Anthropic', 'OpenAI'].includes(model.org)
    //   : false;

    return (
      <div
        className={twMerge(
          'relative flex flex-col gap-0 overflow-hidden rounded-lg border border-clay-20 bg-white hover:border-clay-100 focus:border-clay-100',
          className,
        )}
      >
        <textarea
          className={twMerge(
            'resize-none p-2 text-base font-normal outline-none transition-colors',
            'placeholder:font-light',
            'disabled:pointer-events-none disabled:cursor-not-allowed disabled:bg-clay-20',
            innerClassName,
          )}
          disabled={disabled}
          ref={ref}
          rows={3}
          {...props}
        ></textarea>

        <div className="flex flex-wrap items-end justify-between p-2">
          <div className="flex items-end gap-3">
            {isPrivateAvailable && (
              <Tooltip
                className="max-w-48"
                content={
                  <>
                    Your inference is fully encrypted using TEE and/or equivariant encryption + DP-SL.{' '}
                    <Link
                      className="text-primary-800 hover:text-primary-900"
                      target="_blank"
                      to="https://docs.nesa.ai/nesa/technical-designs/security-and-privacy"
                    >
                      Learn more
                    </Link>
                  </>
                }
              >
                <div>
                  <Label className="flex max-w-fit cursor-pointer items-center gap-2">
                    <Switch
                      checked={params.private}
                      className=" data-[state=checked]:bg-clay-800 data-[state=checked]:hover:bg-clay-800 data-[state=checked]:focus-visible:bg-clay-800"
                      onCheckedChange={(isChecked) => onParamsChange({ private: isChecked })}
                      onFocus={(e) => e.preventDefault()}
                    />
                    <span>Private</span>
                  </Label>
                </div>
              </Tooltip>
            )}
            {isZkAvailable && (
              <Label className="flex max-w-fit cursor-pointer items-center gap-2">
                <Switch
                  checked={params.private}
                  onCheckedChange={(isChecked) => onParamsChange({ useZk: isChecked })}
                />
                <span>ZK Proof</span>
              </Label>
            )}
          </div>
          <div className="flex items-center gap-4">
            {!proVersion?.isPro && (
              <UpgradeToProTriggerModal
                redirectPath={`/models/${model?._id}${section ? `/${section}` : ''}`}
              />
            )}
            <Tooltip
              content={isError ? 'Limit Reached' : tooltipText}
              delayDuration={100}
              open={!!tooltipText || undefined}
              side="top"
            >
              <div>
                <Button
                  className={twMerge(
                    'w-10 px-2',
                    params.private && 'w-fit bg-clay-800 hover:bg-clay-900',
                    isError && 'border border-red-1100 bg-red-100 hover:bg-red-200 disabled:bg-red-100',
                  )}
                  disabled={isSubmitDisabled}
                  isLoading={isLoading}
                  onClick={onSubmit}
                >
                  {params.private && (
                    <div className="mr-1 flex min-w-fit items-center gap-2 border-r border-clay-400/20 py-2 pr-3 text-xs text-clay-300">
                      <Icon className="inline-flex size-4 text-tusk-200" name="lock" />
                      100% Encrypted
                    </div>
                  )}

                  <Icon className={twMerge(isError && 'text-red-1100')} name="send" />
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  },
);
