export { CreateDAI as Page } from './CreateDAI';

import { Suspense, lazy } from 'react';

import { PageLoader } from 'shared/ui/PageLoader';

export const CreateDAIPageLazy = lazy(() =>
  import('./CreateDAI').then((module) => ({ default: module.CreateDAI })),
);

export const CreateDAI = () => (
  <Suspense fallback={<PageLoader />}>
    <CreateDAIPageLazy />
  </Suspense>
);
