import { useState } from 'react';
import { default as ReactDatePicker } from 'react-datepicker';

import { DateTime } from 'luxon';

import { Icon } from 'shared/ui/Icon';
import { Popover } from 'shared/ui/Popover';

import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

type Props = {
  maxDate?: Date;
  minDate?: Date;
  onDateChange: (date: Date | null) => void;
  selectedDate: Date | null;
};

export const DatePicker = ({ maxDate, minDate, onDateChange, selectedDate }: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleDateChange = (date: Date | null) => {
    onDateChange(date);
  };

  return (
    <Popover onOpenChange={setIsPopoverOpen} open={isPopoverOpen}>
      <Popover.Trigger onClick={() => setIsPopoverOpen(true)}>
        <div className="flex items-center justify-between rounded-lg border border-clay-20 px-2 py-2.5 hover:bg-clay-10">
          <span className="w-24 text-left text-sm text-clay-600">
            {selectedDate ? DateTime.fromJSDate(selectedDate).toFormat('yyyy-LL-dd') : 'Select date'}
          </span>
          <Icon className="size-4 text-clay-300" name="calendar2" />
        </div>
      </Popover.Trigger>
      <Popover.Content align="end" className="mt-2 rounded-lg p-4">
        <ReactDatePicker
          inline
          maxDate={maxDate}
          minDate={minDate}
          onChange={handleDateChange}
          selected={selectedDate}
        />
      </Popover.Content>
    </Popover>
  );
};
