import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { ipfsKeys } from './queryKeys';

//QmUWsSJzxRjYbyKzSFQXWAs64ftndnoME2QWJ2hbcFJu1H
export const useGetImageByCidQuery = (cid: string, options: UseQueryOptions<string | undefined> = {}) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      try {
        console.log('useGetImageByCidQuery');
        const { data: blobData } = await axiosInstance.get<Blob>(`/images/${cid}`, {
          responseType: 'blob',
          signal,
          timeout: 60 * 1000,
        });

        console.log('blobData', blobData);
        return URL.createObjectURL(blobData);
      } catch (e) {
        console.log(e);
      }
    },
    queryKey: ipfsKeys.file({ cid, type: 'blob' }),
    ...options,
  });
};
