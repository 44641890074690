import type { ChangeEvent, DragEvent, ReactNode } from 'react';
import { useRef, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import type { IconName } from '../Icon';

import { Icon } from '../Icon';
import { getExtensionsAndMimeTypes } from './helpers';

type Props = {
  accept?: string;
  children?: ReactNode;
  className?: string;
  icon?: IconName;
  iconClassName?: string;
  iconWrapperClassName?: string;
  multiple?: boolean;
  onFileChange: (files: File[]) => void;
};

export const FilePicker = ({
  accept,
  children,
  className,
  icon = 'imagePlus',
  iconClassName,
  iconWrapperClassName,
  multiple = false,
  onFileChange,
}: Props) => {
  const fileRef = useRef<HTMLInputElement>(null);

  const [isDragFile, setIsDragFile] = useState(false);

  const handleClick = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    const files = fileList ? [...fileList] : [];

    onFileChange(files);
  };

  const onDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragFile(true);
  };

  const onDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragFile(false);
  };

  const onDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragFile(false);

    if (!fileRef.current) return;

    const initFiles = [...(e.dataTransfer.files || [])];
    const acceptFiles: File[] = [];

    const { extensions, mimeTypeGroups, mimeTypes } = getExtensionsAndMimeTypes(accept);

    if (!extensions.length && !mimeTypeGroups.length && !mimeTypes.size) {
      acceptFiles.push(...initFiles);
    } else {
      initFiles.forEach((file) => {
        if (
          mimeTypeGroups.some((prefix) => file.type.startsWith(prefix)) ||
          mimeTypes.has(file.type) ||
          extensions.some((ext) => file.name.endsWith(ext))
        ) {
          acceptFiles.push(file);
        }
      });
    }

    const dataTransfer = new DataTransfer();
    acceptFiles.forEach((file) => dataTransfer.items.add(file));
    fileRef.current.files = dataTransfer.files;

    const changeEvent = new Event('change', { bubbles: true });
    const inputEvent = new InputEvent('input', { bubbles: true, composed: true });

    fileRef.current.dispatchEvent(inputEvent);
    fileRef.current.dispatchEvent(changeEvent);
  };

  return (
    <div
      className={twMerge(
        'flex min-h-32 cursor-pointer flex-col items-center justify-center rounded-lg border-[1.2px] border-dashed p-6',
        'transition-all duration-200',
        isDragFile ? 'border-primary-1000 text-primary-1000' : 'border-clay-100 hover:border-clay-500',
        className,
      )}
      onClick={handleClick}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragEnter}
      onDrop={onDrop}
    >
      <input
        accept={accept}
        className="hidden"
        hidden
        multiple={multiple}
        onChange={handleChange}
        ref={fileRef}
        type="file"
      />
      <div className="flex flex-col items-center justify-center gap-2">
        <div
          className={twMerge(
            'mx-auto flex size-11 items-center justify-center rounded-full border border-clay-20 shadow-boxy',
            iconWrapperClassName,
          )}
        >
          <Icon className={twMerge('size-4 text-primary-800', iconClassName)} name={icon} safeArea="0" />
        </div>

        {children || (
          <div className="text-base font-light">
            Drag & Drop or{' '}
            <span className="cursor-pointer text-primary-800 transition-colors hover:text-primary-900">
              choose file
            </span>{' '}
            to upload
          </div>
        )}
      </div>
    </div>
  );
};
