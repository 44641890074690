import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useContext } from 'react';

import type { StripeCheckSubscriptionResponse } from 'shared/api/stripe';
import type { User } from 'shared/api/user/types';

import { queryClient } from 'app/App';
import { useCheckSubscriptionQuery } from 'shared/api/stripe';
import { userKeys } from 'shared/api/user/queryKeys';
import { useCurrentSessionQuery } from 'shared/api/user/useCurrentSessionQuery';
import { useUpdateUserMutation } from 'shared/api/user/useUpdateUserMutation';

export interface UserProps {
  isError: boolean;
  isLoading: boolean;
  isPending: boolean;
  isUpdating: boolean;
  proVersion?: StripeCheckSubscriptionResponse;
  refetchUser: () => void;
  updateUser: (user?: Partial<User>, options?: { sendRequest?: boolean }) => Promise<void>;
  user?: User;
}

export const UserContext = createContext<UserProps>({
  isError: false,
  isLoading: false,
  isPending: false,
  isUpdating: false,
  refetchUser: () => {},
  updateUser: async () => {},
  user: undefined,
});

export const UserContextProvider = ({ children }: PropsWithChildren) => {
  const {
    data,
    isError,
    isLoading,
    isPending,
    refetch: refetchUser,
  } = useCurrentSessionQuery({ retry: false, staleTime: Infinity });
  const { isPending: isUpdating, mutateAsync: updateUserRequest } = useUpdateUserMutation();
  const { data: proVersion } = useCheckSubscriptionQuery({
    enabled: !!data?.user,
  });

  const user = data?.user;

  const handleUserUpdate = useCallback(
    async (
      updatedUser?: { password?: string } & Partial<User>,
      { sendRequest }: { sendRequest?: boolean } = {},
    ) => {
      if (!updatedUser) {
        queryClient.setQueryData(userKeys.singleUser, null);

        return;
      }

      if (sendRequest) {
        console.log('first');
        await updateUserRequest({ ...user, ...updatedUser });
      }

      queryClient.setQueryData(userKeys.singleUser, {
        user: { ...(user || {}), ...updatedUser, password: undefined },
      });
      queryClient.invalidateQueries({ queryKey: userKeys.singleUser });
    },
    [user, updateUserRequest],
  );

  return (
    <UserContext.Provider
      value={{
        isError,
        isLoading,
        isPending: isPending,
        isUpdating,
        proVersion,
        refetchUser,
        updateUser: handleUserUpdate,
        user: user || undefined,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
