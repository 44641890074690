import { Suspense, lazy } from 'react';

import { PageLoader } from 'shared/ui/PageLoader';

export const PostsLazy = lazy(() => import('./Posts').then((module) => ({ default: module.Posts })));

export const Posts = () => (
  <Suspense fallback={<PageLoader />}>
    <PostsLazy />
  </Suspense>
);
