import type { ComponentProps, PropsWithChildren } from 'react';
import { createContext, forwardRef, useContext } from 'react';

import * as Dialog from '@radix-ui/react-dialog';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { Icon } from '../Icon';

const DialogOpenContext = createContext<boolean>(false);

const contentVariants: ComponentProps<typeof motion.div>['variants'] = {
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.98 },
  initial: { opacity: 0, scale: 0.98 },
};
const overlayVariants: ComponentProps<typeof motion.div>['variants'] = {
  animate: { opacity: 1 },
  closed: { opacity: 0 },
};
const transition: ComponentProps<typeof motion.div>['transition'] = {
  duration: 0.15,
};

export const Modal = ({ children, ...props }: Dialog.DialogProps) => {
  return (
    <DialogOpenContext.Provider value={props.open || false}>
      <Dialog.Root {...props}>{children}</Dialog.Root>
    </DialogOpenContext.Provider>
  );
};

const ModalContent = forwardRef<
  HTMLDivElement,
  {
    innerClassName?: string;
    noOverlay?: boolean;
    withAnimation?: boolean;
    wrapperClassName?: string;
  } & Dialog.DialogContentProps
>(
  (
    { children, className, innerClassName, noOverlay, withAnimation = true, wrapperClassName, ...props },
    ref,
  ) => {
    const isOpen = useContext(DialogOpenContext);

    return (
      <AnimatePresence>
        {isOpen && (
          <Dialog.Portal forceMount>
            <div
              className={twMerge(
                'pointer-events-auto fixed inset-x-0 top-0 z-50 flex h-full flex-col p-3 lg:py-12',
                wrapperClassName,
              )}
            >
              <Dialog.Overlay asChild>
                <motion.div
                  animate="animate"
                  className={twMerge('absolute inset-0 bg-clay-1000/50', noOverlay && 'bg-transparent')}
                  exit="closed"
                  initial="closed"
                  transition={withAnimation ? transition : { duration: 0 }}
                  variants={overlayVariants}
                />
              </Dialog.Overlay>
              <motion.div
                animate="animate"
                className={twMerge(
                  'relative z-10 m-auto flex max-h-full w-full max-w-none md:max-w-lg',
                  className,
                )}
                exit="exit"
                initial="initial"
                transition={withAnimation ? transition : { duration: 0 }}
                variants={contentVariants}
              >
                <Dialog.Content
                  {...props}
                  className={twMerge(
                    'w-full overflow-auto rounded-xl bg-white p-5 scrollbar-none focus:outline-none',
                    innerClassName,
                  )}
                  ref={ref}
                >
                  {children}
                </Dialog.Content>
              </motion.div>
            </div>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    );
  },
);

const ModalCloseButton = ({ className }: ClassName) => {
  return (
    <Dialog.Close className={twMerge('absolute right-2 top-2 outline-none', className)}>
      <Icon className="text-clay-500 transition-colors hover:text-clay-1000" name="close" />
    </Dialog.Close>
  );
};

const ModalTitle = ({ children, className }: PropsWithChildren<ClassName>) => {
  return (
    <Dialog.DialogTitle className={twMerge('mb-3 text-xl font-semibold', className)}>
      {children}
    </Dialog.DialogTitle>
  );
};

const ModalScrollableContent = ({ children, className }: PropsWithChildren<ClassName>) => {
  return <div className={twMerge('overflow-scroll ', className)}>{children}</div>;
};

Modal.Content = ModalContent;
Modal.CloseButton = ModalCloseButton;
Modal.Title = ModalTitle;
Modal.ScrollableContent = ModalScrollableContent;
