import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import { useUser } from 'app/stores/user';
import { useGetImageByCidQuery } from 'shared/api/ipfs/useGetImageByCidQuery';
import { useLogoutMutation } from 'shared/api/user/useLogoutMutation';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Icon, type IconName } from 'shared/ui/Icon';
import { Popover } from 'shared/ui/Popover';
import { toaster } from 'shared/ui/Toast';

type Props = {
  className?: string;
};

export const UserLink = ({ className }: Props) => {
  const [open, setOpen] = useState(false);
  const { updateUser, user } = useUser();
  const navigate = useNavigate();
  const sm = useMinWidthMediaQuery('sm');
  const { mutateAsync: logout } = useLogoutMutation();
  const { data: imageUrl } = useGetImageByCidQuery(user?.avatar_cid || '');

  const userItems: { disabled?: boolean; icon: IconName; id: string; label: string; path: string }[] = [
    { icon: 'userCircle', id: 'profile', label: 'My Profile', path: `/account/${user?._id}` },
    { icon: 'wallet', id: 'wallet', label: 'Wallet', path: '/wallet' },
    { icon: 'database', id: 'nodes', label: 'My Nodes', path: '/nodes' },
    { disabled: true, icon: 'trophy', id: 'rewards', label: 'Rewards', path: '/leader-board' },
    { icon: 'settings', id: 'settings', label: 'Settings', path: '/settings' },
    { icon: 'briefcase', id: 'collections', label: 'My Collections', path: '/model-collections' },
    { icon: 'logs', id: 'query-history', label: 'Query History', path: '/query-history' },
  ];

  const handleLogout = () => {
    logout();
    updateUser();
    setOpen(false);
  };

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <Popover.Trigger>
        <div
          className={twMerge(
            'flex size-9 cursor-pointer items-center justify-center rounded-full bg-tusk-100 pb-1 font-klapt text-lg/none font-bold uppercase transition-colors hover:bg-tusk-300 hover:outline hover:outline-4 hover:outline-clay-10',
            imageUrl && 'bg-white pb-0 hover:bg-white',
            className,
          )}
        >
          {imageUrl ? (
            <img className="size-7 rounded-full" src={imageUrl} />
          ) : (
            user?.first_name?.slice(0, 1) ?? (user?.email?.slice(0, 1) || '-')
          )}
        </div>
      </Popover.Trigger>
      <Popover.Content
        align="end"
        className="mt-4 flex w-48 flex-col rounded-lg p-1 shadow-md sm:ml-4 sm:mt-0"
        side={sm ? 'right' : 'bottom'}
      >
        {userItems.map((item) => (
          <div
            className="flex cursor-pointer items-center gap-2 rounded px-3 py-2 hover:bg-clay-10"
            key={item.id}
            onClick={() => {
              if (item.disabled) {
                toaster.info('Coming soon');
                return;
              }
              navigate(item.path);
              setOpen(false);
            }}
          >
            <Icon className="size-3.5 text-clay-350" name={item.icon} safeArea="0" />
            <span className="text-sm text-clay-700">{item.label}</span>
          </div>
        ))}

        <div className="my-1.5 h-px bg-clay-20"></div>

        <div
          className="flex cursor-pointer items-center gap-2 rounded px-3 py-2 hover:bg-clay-10"
          onClick={handleLogout}
        >
          <Icon className="size-3.5 text-red-900" name="logOut" />
          <span className="text-sm text-clay-700">Log Out</span>
        </div>
      </Popover.Content>
    </Popover>
  );
};
