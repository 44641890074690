import { useEffect, useState } from 'react';

import Lottie from 'lottie-react';

import { ERROR_CODES } from 'pages/QueryPage/config';

import dotsStep00 from './assets/dots/dots-step-00.json';
import dotsStep01 from './assets/dots/dots-step-01.json';
import dotsStep01Loading from './assets/dots/dots-step-01-loading.json';
import dotsStep02 from './assets/dots/dots-step-02.json';
import dotsStep02Loading from './assets/dots/dots-step-02-loading.json';
import dotsStep03 from './assets/dots/dots-step-03.json';
import dotsStep03Loading from './assets/dots/dots-step-03-loading.json';
import dotsStep04 from './assets/dots/dots-step-04.json';
import dotsStep04Loading from './assets/dots/dots-step-04-loading.json';
import dotsStep05 from './assets/dots/dots-step-05.json';
import dotsStep05Loading from './assets/dots/dots-step-05-loading.json';
import dotsStep06 from './assets/dots/dots-step-06.json';
import dotsStepError from './assets/dots/dots-step-error.json';
// import endingAnimation from './assets/dots-ending.json';
// import errorAnimation from './assets/dots-error.json';
// import loadingAnimation from './assets/dots-loading.json';
// import progressAnimation from './assets/dots-progress.json';

type Props = {
  code: null | number;
};

const getAnimation = (code?: null | number) => {
  if (!code) return {};

  const hasError = ERROR_CODES.includes(code);
  // const isLoading = [300, 302, 303, 304].includes(code);
  // const isStopppedLoading = [301].includes(code);
  // const isProgress = [305, 306].includes(code);
  // const isFinished = [307].includes(code);

  if (hasError) {
    return { animation: dotsStepError, loop: false };
  }

  if (code === 300) {
    return { animation: dotsStep00, loop: false };
  }

  if (code === 301) {
    return { animation: dotsStep01, loadingAnimation: dotsStep01Loading, loop: false };
  }

  if (code === 302) {
    return { animation: dotsStep02, loadingAnimation: dotsStep02Loading, loop: false };
  }

  if (code === 303) {
    return { animation: dotsStep03, loadingAnimation: dotsStep03Loading, loop: false };
  }

  if (code === 304) {
    return { animation: dotsStep04, loadingAnimation: dotsStep04Loading, loop: false };
  }

  if ([305, 306].includes(code)) {
    return { animation: dotsStep05, loadingAnimation: dotsStep05Loading, loop: false };
  }

  if (code === 307) {
    return { animation: dotsStep06, loop: false };
  }

  // if ([302, 303].includes(code)) {
  //   return { animation: loadingAnimation, loop: 1 };
  // }
  // if (isLoading) {
  //   return { animation: loadingAnimation, loop: false };
  // }
  // if (isStopppedLoading) {
  //   return { animation: loadingAnimation, loop: false };
  // }
  // if (isProgress) {
  //   return { animation: progressAnimation, loop: false };
  // }
  // if (isFinished) {
  //   return { animation: endingAnimation, loop: false };
  // }

  // if (!code) {
  //   return { animation: loadingAnimation, loop: false };
  // }

  return {};
};

export const Inference = ({ code }: Props) => {
  const [isCompletedAnimation, setICompletedAnimation] = useState(false);

  const { animation, loadingAnimation, loop } = getAnimation(code);

  useEffect(() => {
    if (animation) {
      setICompletedAnimation(false);
    }
  }, [animation]);

  return (
    <div className="flex flex-col gap-1 overflow-hidden">
      <Lottie
        animationData={((isCompletedAnimation && loadingAnimation) || animation) ?? dotsStep00}
        className="max-w-80"
        loop={(loadingAnimation && isCompletedAnimation) || (loop ?? 0)}
        onComplete={() => setICompletedAnimation(true)}
      />
    </div>
  );
};
