import { useEffect, useRef, useState } from 'react';

import { Portal } from '@radix-ui/react-portal';
import { AnimatePresence, motion } from 'framer-motion';
import { twJoin, twMerge } from 'tailwind-merge';

import type { Model } from 'shared/api/models/types';

import { useUser } from 'app/stores/user';
import { getCategoryLabel } from 'features/CategoryModelsDropdown';
import { LLMParams, type LLMQueryParams } from 'features/LLMQuery/ui/LLMParams';
import { QueryHistoryContent } from 'features/ModelQueryHistory';
import { QueryLimitOverview } from 'features/QueryLimitOverview';
import { Button } from 'shared/ui/Button';
import { ButtonTab } from 'shared/ui/ButtonTab';
import { Icon } from 'shared/ui/Icon';
import { toaster } from 'shared/ui/Toast';

import flagSrc from '../assets/encrypt-flag.png';
import { LLMChatContent } from './LLMChatContent';

type Props = {
  isApiModel: boolean;
  isOpen: boolean;
  isRequestingSession?: boolean;
  isTyping: boolean;
  model: Model;
  onMessageSend: (message: string) => void;
  onOpenChange: (open: boolean) => void;
  onQueryParamsChange: (params: Partial<LLMQueryParams>) => void;
  queryParams: LLMQueryParams;
  sessionId: string | undefined;
};

const hiddenAnimate = {
  width: '4.25rem',
};
const expandedAnimate = {
  width: '24.5rem',
};

export const ExpandedViewChat = ({
  isApiModel,
  isOpen,
  isRequestingSession,
  isTyping,
  model,
  onMessageSend,
  onOpenChange,
  onQueryParamsChange,
  queryParams,
  sessionId,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isHistory, setIsHistory] = useState(false);

  const { user } = useUser();

  const onOpenChangeRef = useRef(onOpenChange);
  onOpenChangeRef.current = onOpenChange;

  useEffect(() => {
    const abortController = new AbortController();

    document.addEventListener(
      'keydown',
      (e) => {
        if (e.code === 'Escape') {
          onOpenChangeRef.current(false);
        }
      },
      { signal: abortController.signal },
    );

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <AnimatePresence>
      {isOpen && (
        <Portal>
          <motion.div
            animate={{ opacity: 1 }}
            className="fixed inset-0 z-50 flex flex-col bg-clay-1000/50 px-24 py-12"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            onClick={() => onOpenChange(false)}
          >
            <div className="flex flex-1 flex-col rounded-xl bg-white" onClick={(e) => e.stopPropagation()}>
              <header className="flex h-20 min-h-20 border-b border-clay-20">
                <div className="flex flex-1 items-center border-r border-clay-20 px-4">
                  <div className="flex flex-1 items-center gap-2.5">
                    <img
                      className="size-10 overflow-hidden rounded-lg object-cover object-center"
                      src={model?.tinyImage || model?.image?.replace('storage.googleapis.com/', '')}
                    />
                    <div className="flex flex-col">
                      <h3 className="text-xl/6 font-bold text-clay-900">{model?.modelName ?? model?.name}</h3>
                      <p className="text-sm text-clay-500">{getCategoryLabel(model.type)}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-1">
                    <ButtonTab
                      className="flex items-center gap-2.5"
                      color="gray"
                      onClick={() => toaster.info('Coming Soon')}
                      selected={false}
                    >
                      <Icon className="size-4" name="paintbrush" />
                      Clean
                    </ButtonTab>
                    <ButtonTab
                      className="flex items-center gap-2.5"
                      color="gray"
                      onClick={() => {
                        setIsHistory(!isHistory);
                        if (!isExpanded) {
                          setIsExpanded(true);
                        }
                      }}
                      selected={false}
                    >
                      <Icon
                        className={twMerge('size-4', isHistory && 'stroke-clay-900 text-transparent')}
                        name={isHistory ? 'penLine' : 'fileClock'}
                      />
                      <span>{isHistory ? 'Parameters' : 'History'}</span>
                    </ButtonTab>
                  </div>
                </div>
                <motion.div
                  animate={isExpanded ? expandedAnimate : hiddenAnimate}
                  className="flex items-center justify-end overflow-hidden px-3.5"
                  transition={{ duration: 0.2 }}
                >
                  <AnimatePresence>
                    {isExpanded && (
                      <motion.div
                        animate={{ opacity: 1 }}
                        className="flex flex-1 items-center gap-2.5"
                        exit={{ opacity: 0 }}
                        initial={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <Icon
                          className={twJoin('size-4', isHistory ? 'text-clay-400' : 'stroke-clay-400')}
                          name={isHistory ? 'history' : 'penLine'}
                          safeArea="0"
                        />
                        <span className="text-base font-semibold text-clay-900">
                          {isHistory ? 'History' : 'Parameters'}
                        </span>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <button
                    className="flex size-10 min-w-10 items-center justify-center rounded-lg bg-clay-10"
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    <Icon className="size-5 text-clay-350" name={isExpanded ? 'panelRight' : 'panelLeft'} />
                  </button>
                </motion.div>
              </header>
              <div className="flex flex-1">
                <div className="relative flex-1 border-r border-clay-20">
                  <div className={twMerge('absolute inset-0')}>
                    <AnimatePresence>
                      <QueryLimitOverview
                        className={twJoin(
                          'absolute left-1/2 top-2.5 z-50 -translate-x-1/2 transition-all',
                          queryParams.private && 'top-9',
                        )}
                        modelId={model._id}
                      />
                    </AnimatePresence>
                    <AnimatePresence>
                      {queryParams.private && (
                        <motion.div
                          animate={{ opacity: 1 }}
                          className="absolute left-1/2 top-0 z-50 flex -translate-x-1/2 items-center justify-center"
                          exit={{ opacity: 0 }}
                          initial={{ opacity: 0 }}
                        >
                          <div
                            className="inset-x-0 top-0 flex min-w-72 items-center justify-center bg-contain bg-top bg-no-repeat px-10 pb-2 pt-1 text-sm text-white"
                            style={{ backgroundImage: `url(${flagSrc})` }}
                          >
                            <Icon className="mr-2 inline-flex size-4 text-tusk-200" name="lock" />
                            <span className="mt-px whitespace-nowrap leading-none">
                              100% Encrypted Request
                            </span>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                    <LLMChatContent
                      isRequestingSession={isRequestingSession}
                      isTyping={isTyping}
                      model={model}
                      onMessageSend={onMessageSend}
                      onQueryParamsChange={onQueryParamsChange}
                      queryParams={queryParams}
                      sessionId={sessionId}
                    />
                  </div>
                </div>
                <motion.div
                  animate={isExpanded ? expandedAnimate : hiddenAnimate}
                  className="flex flex-col overflow-hidden p-3.5"
                  transition={{ duration: 0.2 }}
                >
                  <div className="relative mb-2.5 flex-1">
                    <div className="absolute inset-0 overflow-auto">
                      <AnimatePresence>
                        {isExpanded && (
                          <motion.div
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            initial={{ opacity: 0 }}
                            transition={{ duration: 0.2 }}
                          >
                            {isHistory ? (
                              <>
                                <div className="mb-4 flex justify-center">
                                  <div className="flex h-[2.875rem] items-center gap-1 rounded-lg bg-clay-20 px-1">
                                    <ButtonTab className="h-9" color="white" selected={true}>
                                      Query History
                                    </ButtonTab>
                                    <ButtonTab
                                      className="h-9"
                                      color="white"
                                      onClick={() => toaster.info('Coming Soon')}
                                      selected={false}
                                    >
                                      Session History
                                    </ButtonTab>
                                  </div>
                                </div>
                                <QueryHistoryContent
                                  classNameViewAll="sticky"
                                  modelId={model._id}
                                  userId={user?._id || ''}
                                />
                              </>
                            ) : (
                              <LLMParams
                                className="flex flex-col gap-3.5"
                                classNameSliderWrapper="pb-3.5 border-b border-clay-20 last:border-none"
                                isApiModel={isApiModel}
                                onChange={onQueryParamsChange}
                                queryParams={queryParams}
                              />
                            )}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  </div>
                  <div className="flex flex-none justify-end">
                    <Button
                      className="gap-0 px-2.5"
                      color="dark"
                      onClick={() => onOpenChange(false)}
                      size="extra-small"
                    >
                      <Icon className="size-4" name="shrink" />
                      <AnimatePresence>
                        {isExpanded && (
                          <motion.div
                            animate={{ opacity: 1, width: 'auto' }}
                            className="overflow-hidden whitespace-nowrap pl-2.5"
                            exit={{ opacity: 0, width: 0 }}
                            initial={{ opacity: 0, width: 0 }}
                            transition={{ duration: 0.2 }}
                          >
                            Exit Fullscreen
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </Button>
                  </div>
                </motion.div>
              </div>
            </div>
          </motion.div>
        </Portal>
      )}
    </AnimatePresence>
  );
};
