export const userKeys = {
  singleUser: ['user'],
  subscribeEmailStatus: (token: string) => [...userKeys.singleUser, '/email/unsubscribe-email', token],
  user: ({ userId }: { userId: string }) => [...userKeys.singleUser, userId],
  usersByNames: (params: { limit: number; name: string; skip: number }) => [
    ...userKeys.singleUser,
    'users/find',
    params,
  ],
};
