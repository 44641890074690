export const trendingModelNames = [
  'Orenguteng/Llama-3-8B-Lexi-Uncensored',
  'nesa/LazyMix-Uncensored',
  'black-forest-labs/FLUX.1-schnell',
  'gpt-4o',
  'claude-3-opus-20240229',
  'dall-e-3',
  'stabilityai/sdxl-turbo',
];

export const trendingTextModelNames = [
  'Orenguteng/Llama-3-8B-Lexi-Uncensored',
  'gpt-4o',
  'claude-3-opus-20240229',
  'meta-llama/Llama-3.2-1B-Instruct',
];
