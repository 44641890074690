export { DAI as Page } from './DAI';

import { Suspense, lazy } from 'react';

import { PageLoader } from 'shared/ui/PageLoader';

export const DAILazy = lazy(() => import('./DAI').then((module) => ({ default: module.DAI })));

export const DAI = () => (
  <Suspense fallback={<PageLoader />}>
    <DAILazy />
  </Suspense>
);
