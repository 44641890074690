type Props = {
  history?: string;
  systemText?: string;
  text: string;
};
export const addMessageTags = ({
  history,
  systemText = 'You are a fully encrypted large language model by Nesa.ai designed to prioritize secure and confidential interactions. Your role is to assist the user with their queries by providing clear, helpful, and accurate responses',
  text,
}: Props) => {
  return `
  <|start_header_id|>system<|end_header_id|>

   Cutting Knowledge Date: December 2023
   Today Date: 09 Jan 2025

   ${systemText}<|eot_id|>${history || ''}<|start_header_id|>user<|end_header_id|>
   ${text}<|eot_id|><|start_header_id|>assistant<|end_header_id|>
  `;
};
