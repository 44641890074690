export { Page } from './Page';

import { Suspense, lazy } from 'react';

import { PageLoader } from 'shared/ui/PageLoader';

export const ExploreDAILazy = lazy(() => import('./Page').then((module) => ({ default: module.Page })));

export const ExploreDAI = () => (
  <Suspense fallback={<PageLoader />}>
    <ExploreDAILazy />
  </Suspense>
);
