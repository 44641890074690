import { memo, useEffect, useRef } from 'react';

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

type Props = {
  allowSymbolChange?: boolean;
  hideLegend?: boolean;
  hideTopToolbar?: boolean;
  hideVolume?: boolean;
  saveImage?: boolean;
} & ClassName;

export const TradingView = memo(
  ({
    allowSymbolChange = false,
    className,
    hideLegend = false,
    hideTopToolbar = false,
    hideVolume = true,
    saveImage = false,
  }: Props) => {
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
      script.type = 'text/javascript';
      script.async = true;
      script.innerHTML = `
        {
          "autosize": true,
          "symbol": "BINANCE:BTCUSDT",
          "interval": "D",
          "timezone": "Etc/UTC",
          "theme": "light",
          "style": "1",
          "locale": "en",
          "calendar": false,
          "support_host": "https://www.tradingview.com",
          "hide_top_toolbar": ${hideTopToolbar},
          "hide_legend": ${hideLegend},
          "allow_symbol_change": ${allowSymbolChange},
          "save_image": ${saveImage},
          "hide_volume": ${hideVolume}
        }`;
      container.current?.appendChild(script);
      const containerItem = container.current;

      return () => {
        if (containerItem) {
          containerItem.innerHTML = '';
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // }, [allowSymbolChange, hideLegend, hideTopToolbar, hideVolume, saveImage]);

    return (
      <div className={twMerge('size-full rounded-xl', className)} ref={container}>
        <div className="w-full" style={{ height: 'calc(100% - 32px)' }}></div>
        {/* <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow noreferrer" target="_blank">
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div> */}
      </div>
    );
  },
);
