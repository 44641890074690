import { ChatClient } from 'nesa.js';

import { nesaTestnet } from 'shared/config/networks/nesaTestnet';

export let chatClient: ChatClient | undefined = undefined;

type Props = {
  address: string | undefined;
  authToken?: string;
  isByPass?: boolean;
  modelName: string | undefined;
  walletType: string | undefined;
};

export const createChatClient = ({ address, authToken, isByPass, modelName, walletType }: Props) => {
  if (!modelName) return;
  if (!isByPass && (!walletType || !address)) return;

  const accessTokenDefault = localStorage.getItem('user');
  const accessToken = authToken || accessTokenDefault;

  chatClient && chatClient?.requestCloseHeartbeat();

  chatClient = new ChatClient({
    ...(isByPass
      ? {
          agentUrl: 'https://api-agent.dev.nesa.ai',
          authToken:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NzM2NjdkM2MxYjRhYTUxMmYwZTkzODYiLCJlbWFpbCI6ImdyaXNoYW5pYTE5OThAZ21haWwuY29tIiwiaWF0IjoxNzM2OTQ3NDgwLCJleHAiOjE3Mzg2NzU0ODB9.9BK_VolIPsGqts_-XGolF3Vfrxo8Cnw3HOhsGSdHDxc' ||
            // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NjJkNTc2NTdmNGRlODMwMzg4ZGI4MTkiLCJlbWFpbCI6ImJhc2tha292YXlhbmFAZ21haWwuY29tIiwiaWF0IjoxNzM2MjU4ODcyLCJleHAiOjE3MzYyNjI0NzJ9.xNK5k7X3JqyUthpA1AgkrfpTP5-N__X5oPaeSd5Yp8Q' ||
            // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NjJkNTc2NTdmNGRlODMwMzg4ZGI4MTkiLCJlbWFpbCI6ImJhc2tha292YXlhbmFAZ21haWwuY29tIiwiaWF0IjoxNzMzMzk1OTgxLCJleHAiOjE3MzMzOTk1ODF9.vk40py7iY1OHde2fsfFzr0IJ1Pm2kvz_6DmwKgffxV0' ||
            accessToken ||
            undefined,
        }
      : {
          walletName: walletType === 'metamask_snap_leap' ? 'npm:@leapwallet/metamask-cosmos-snap' : 'keplr',
        }),
    chainInfo: nesaTestnet,
    isByPass,
    modelName: modelName,
  });

  return () => chatClient?.requestCloseHeartbeat();
};
