import { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { Icon } from 'shared/ui/Icon';
import { Label } from 'shared/ui/Label';
import { Popover } from 'shared/ui/Popover';
import { Switch } from 'shared/ui/Switch';
import { toaster } from 'shared/ui/Toast';
import { Tooltip } from 'shared/ui/Tooltip';

type Props = {
  isEnabled?: boolean;
  onEnabledChange?: (isEnabled: boolean) => void;
};

const features = [
  { description: 'High understandability of explanations provided', label: 'Clarity' },
  { description: 'High reliability, consistency and accuracy in response', label: 'ROBUSTNESS' },
  { description: 'Low energy consumption during training and inference', label: 'energy efficiency' },
  { description: 'High safety and trustworthiness in response', label: 'SAFETY' },
];

export const NesaTrust = ({ isEnabled = false, onEnabledChange }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover onOpenChange={setIsOpen} open={isOpen}>
      <Popover.Trigger
        className={twMerge(
          'flex h-9 w-fit cursor-pointer items-center rounded-lg bg-white px-3 py-1.5 text-sm/none transition-all inner-border-0',
          isOpen && 'shadow-md',
          isEnabled && 'bg-green-10',
        )}
      >
        <Icon
          className={twMerge('mr-1 size-4 text-clay-350', isEnabled && 'stroke-none text-green-600')}
          name={isEnabled ? 'shieldCheck' : 'shield'}
        />
        <span className="text-sm/none">Nesa Trust</span>
        <Icon
          className={twMerge('size-6 text-clay-400 transition-transform', isOpen && 'rotate-180')}
          name="arrowDownSm"
        />
      </Popover.Trigger>

      <Popover.Content align="end" alignOffset={4} className="rounded-lg !px-4 !py-0" sideOffset={4}>
        <div className="flex flex-row gap-0">
          <div className="flex flex-col items-center justify-center gap-2 border-r border-clay-20 py-4 pl-2 pr-4">
            <Icon className="size-6 text-clay-700" name="shieldCheck" />

            <div className="relative flex flex-1 flex-col">
              <div className="absolute left-0 top-0 -translate-x-3 text-[8px]/none font-light text-clay-400">
                100
              </div>
              <div className="absolute bottom-0 left-0 -translate-x-1 text-[8px]/none font-light text-clay-400">
                0
              </div>
              <Tooltip
                content={
                  <div className="flex flex-col items-center justify-center gap-1 text-xs/none font-light">
                    <div>High Trust</div>
                    <div>75-100</div>
                  </div>
                }
                delayDuration={100}
                side="left"
              >
                <div className="flex flex-1 flex-col px-2">
                  <div className="w-1 flex-1 rounded-t-full bg-gradient-to-b from-blue-600 from-80% to-green-600 to-95%" />
                </div>
              </Tooltip>
              <Tooltip
                content={
                  <div className="flex flex-col items-center justify-center gap-1 text-xs/none font-light">
                    <div>Good Trust</div>
                    <div>50-74</div>
                  </div>
                }
                delayDuration={100}
                side="left"
              >
                <div className="flex flex-1 flex-col px-2">
                  <div className="w-1 flex-1 bg-gradient-to-b from-green-600 from-80% to-yellow-500 to-95%" />
                </div>
              </Tooltip>
              <Tooltip
                content={
                  <div className="flex flex-col items-center justify-center gap-1 text-xs/none font-light">
                    <div>Average Trust</div>
                    <div>25-49</div>
                  </div>
                }
                delayDuration={100}
                side="left"
              >
                <div className="flex flex-1 flex-col px-2">
                  <div className="w-1 flex-1 bg-gradient-to-b from-yellow-500 from-80% to-red-900 to-100%" />
                </div>
              </Tooltip>
              <Tooltip
                content={
                  <div className="flex flex-col items-center justify-center gap-1 text-xs/none font-light">
                    <div>Poor Trust</div>
                    <div>0-24</div>
                  </div>
                }
                delayDuration={100}
                side="left"
              >
                <div className="flex flex-1 flex-col px-2">
                  <div className="w-1 flex-1 rounded-b-full bg-red-900" />
                </div>
              </Tooltip>

              {/* <div className="w-1 flex-1 rounded-t-full bg-gradient-to-b from-blue-600 from-40% to-green-600 to-70%"></div>
              <div className="w-1 flex-1 bg-gradient-to-b from-green-600 from-40% to-yellow-500 to-60%"></div>
              <div className="w-1 flex-1 rounded-b-full bg-gradient-to-b from-yellow-500 from-20% to-red-900 to-40%"></div> */}
            </div>
          </div>

          <div className="flex flex-col py-4 pl-6">
            <h2 className="mb-2 flex items-center gap-2 text-2xl font-normal text-clay-900">
              Nesa Trust Scores{' '}
              <Tooltip
                className="max-w-72"
                content={
                  <>
                    Nesa specializes in trustworthy AI, which prioritizes identifying and tracking AI models
                    scored high on safety, fairness, truthfulness, and robustness, and low on hallucination.{' '}
                  </>
                }
                side="top"
              >
                <Icon
                  className="mt-0.5 size-4 cursor-pointer text-clay-350 transition-colors hover:text-clay-800"
                  name="infoCircle"
                />
              </Tooltip>
            </h2>

            <p className="mb-3 max-w-72 text-sm font-light text-clay-500">
              When this setting is on, model cards in the Playground will be color coded based on their Nesa
              Trust Score, which considers the following heuristics:
            </p>

            <div className="mb-6 flex items-center gap-2">
              {features.map(({ description, label }) => (
                <Tooltip
                  className="max-w-44 p-2 text-center text-sm/none font-light"
                  content={description}
                  key={label}
                  side="top"
                >
                  <div className="ml-auto shrink-0 select-none truncate rounded-[4px] border border-clay-20 bg-white px-1 py-0.5 text-xs uppercase text-clay-300">
                    {label}
                  </div>
                </Tooltip>
              ))}
            </div>

            <Label className="flex cursor-pointer items-center gap-2">
              <Switch
                checked={isEnabled}
                onCheckedChange={(checked) => {
                  onEnabledChange?.(checked);
                  setIsOpen(false);
                  if (checked) {
                    toaster.info('Coming Soon');
                  }
                }}
              />
              {isEnabled ? 'On' : 'Off'}
            </Label>
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );
};
